import React, { FunctionComponent } from 'react'
import FormBuilder, { FormBuilderProps } from 'Root/Shared/components/builders/FormBuilder'
import { t } from 'ttag'

declare global {
    interface Window { generator: any, uniqid: string, dataLayer: any }
}
const GenericTrialForm: FunctionComponent = () => {
    const WORKFLOW_ACTION = "/EasyRedmine/workflows/Invoke?token=CfDJ8K2ikAJDM3NKjLd91iVEJfANjcCqtuovzNZMCh7xOOrTeeS8BP8cMfa0mmiBYQlvNKhHzEMhVREEVxr7Ky-bJMxeJp2xK4We7fKoNYO8s9XpFOLjLonGvtruldI_PHOsrzM4Q_whMlSUy0aDI-ucaYfQaHf4LZCP1T0GnZ8jaqL0pLWOQ-B-JVajkINFF61udbk4z9QTkwSg9tb4S1-CPDdHyN747bY4BUPCQT-OaJze"
    // const WORKFLOW_ACTION = "/workflows/Invoke?token=CfDJ8Lddv4IhhiRKi8bRrd_lQeTH5T5q2K1FfTVkmtGP_8HYB0b2p22W3rjCV2kgbyWS6WtECoRYjEQSVdk2Sq3oT6iGM7fwn_8bm_rRJh_PxF-9TlwKJd6wd8qseZfTbnrx_Yzv4bop4NvLQUapW-_yZzdu-L1Uk5RIJIGMCT6_KklrKe0GkMtbaz_APSSK9zvVY2BS9e2RuoGOJbzFDEfa57JD9S5p3U3YSVPwEZuRC94d"
    const inputDefault = {
        "puxFormElementOptions": {
            "formElementOptionsDisabled": false,
            "formElementOptionsAutofocus": false,
            "formElementOptionsAutocomplete": false,
            "formElementOptionsMultiple": false,
            "formElementOptionsStep": 1,
            "formElementOptionsCssClass": "mauticform-input",
            "formElementOptionsReadOnly": false
        },
        "puxFormElementValidation": {
            "formElementValidationRequired": false,
            "formElementValidationPattern": undefined,
            "formElementValidationMin": NaN,
            "formElementValidationMax": NaN,
            "formElementValidationMinLength": NaN,
            "formElementValidationMaxLength": NaN
        },
        "metadata": {
            "alignment": "Justify",
            "size": 100
        }
    }

    const data: FormBuilderProps = {
        "formBuilderData": {
            "contentType": "PuxForm",
            "displayText": "mauticform_trialrequest",
            "metadata": {
                alignment: "",
                size: 100,
            },
            "puxFormOptions": {
                "formAction": WORKFLOW_ACTION,
                "formMethod": "POST",
                "formEncType": "multipart/form-data",
                "formAntiForgeryToken": false,
                "formID": "mauticform_trialrequest",
                "formSuccessMessage": {
                    "html": ""
                },
                "formCustomCssClass": "",
                "formShowLabels": true,
                "formRedirectUrl": {
                    internal: [false],
                    url: "",
                    text: ""
                }
            },
            "puxFormRecaptchaOptions": {
                "formRecaptchaSiteKey": "6LddcacpAAAAAG0STmwsbcYzM_aqYijcUcMnAF0C",
                "formValidateRecaptcha": false
            },
            "puxWidgetProperties": {
                "widgetPropertiesPartID": "",
                "widgetPropertiesPartMarginTop": "",
                "widgetPropertiesPartPaddingTop": "",
                "widgetPropertiesPartMarginBottom": "",
                "widgetPropertiesPartPaddingBottom": "",
                "widgetPropertiesPartMobileOrder": ""
            },
            "flow": {
                "widgets": [
                    {
                        ...inputDefault,
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[email]",
                        "inputType": "email",
                        "puxFormElement": {
                            "formElementDefaultValue": "",
                            "formElementID": "company-email",
                            "formElementName": "mauticform[email]",
                            "formElementLabel": t`Redmine.TrialCTAForm.Email`,
                            "formElementPlaceholder": t`Redmine.TrialCTAForm.YourEmail`
                        },
                        "puxFormElementValidation": {
                            "formElementValidationRequired": true,
                            "formElementValidationMax": NaN,
                            "formElementValidationMaxLength": NaN,
                            "formElementValidationMin": NaN,
                            "formElementValidationMinLength": NaN,
                            "formElementValidationPattern": t`Redmine.TrialCTAForm.BusinessEmailRegex`,
                        }
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[first_name]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_first_name",
                            "formElementName": "mauticform[first_name]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[last_name]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_last_name",
                            "formElementName": "mauticform[last_name]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[identifier]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": IDGenerator(),
                            "formElementID": "mauticform_input_trialrequest_identifier",
                            "formElementName": "mauticform[identifier]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[affiliate_account_id]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_affiliate_account_id",
                            "formElementName": "mauticform[affiliate_account_id]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[affiliate_conversion_id]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_affiliate_conversion_id",
                            "formElementName": "mauticform[affiliate_conversion_id]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldSubmit",
                        "displayText": "",
                        "formElementName": t`Redmine.TrialCTAForm.Submit`,
                        "buttonColor": "Button--yellow",
                        "buttonSize": "Button--large",
                        "buttonType": "Button--primary",
                        "metadata": {
                            "alignment": "Justify",
                            "size": 100
                        }
                    }
                ]
            },
            "mautic": {
                "formContainerMauticID": "4",
                "formContainerSource": "trial",
                "formContainerSubmissionID": "www.easyredmine.com;demo;"
            }
        }
    }

    function IDGenerator() {
        const length = 77
        const timestamp = +new Date()

        const _getRandomInt = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min
        }

        const ts = timestamp.toString()
        const parts = ts.split(``).reverse()
        let id = ``

        for (let i = 0; i < length; ++i) {
            const index = _getRandomInt(0, parts.length - 1)
            id += parts[index]
        }
        return id
    }

    return <FormBuilder {...data} />
}

export default GenericTrialForm









































































































































































































































































































































































































































































































































































