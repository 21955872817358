import './MauticTrialForm.scss'

import React, { FunctionComponent } from 'react'

import TrialFormBR from './TrialForms/TrialFormBR'
import TrialFormDE from './TrialForms/TrialFormDE'
import TrialFormEN from './TrialForms/TrialFormEN'
import TrialFormES from './TrialForms/TrialFormES'
import TrialFormFR from './TrialForms/TrialFormFR'
import TrialFormJP from './TrialForms/TrialFormJP'
import TrialFormKR from './TrialForms/TrialFormKR'
import TrialFormRU from './TrialForms/TrialFormRU'
import GenericTrialForm from './TrialForms/GenericTrialForm'

const MauticTrialForm: FunctionComponent = () => {
  switch (process.env.LOCALE || `en`) {
    case `br`:
      return <TrialFormBR />
    case `de`:
      return <TrialFormDE />
    case `es`:
      return <TrialFormES />
    case `fr`:
      return <TrialFormFR />
    case `jp`:
      return <TrialFormJP />
    case `kr`:
      return <TrialFormKR />
    case `ru`:
      return <TrialFormRU />
    default:
      if (process.env.GATSBY_EN_HAS_ORCHARD_FORM === "true") {
        return <GenericTrialForm />
      } else {
        return <TrialFormEN />
      }
  }
}

export default MauticTrialForm
