import './ArticlePage.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import {
  GenericPageProps,
  IEasySoftwareArticlePage,
} from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const ArticlePage: FunctionComponent<
  GenericPageProps<IEasySoftwareArticlePage>
> = (props) => {
  const data = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const widgetsData = props.pageContext.widgets
  const sideCardWidgetData = props.pageContext.sideCardWidgets
  const breadCrumbsData = props.pageContext.breadcrumbs
  const editorPath = `articleWidgets,widgets`

  return (
    <Layout
      localizedPath={data.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={data.contentItemId}
      pageProperties={data.puxPageProperties}
    >
      <div className='ArticlePage Container'>
        <EasySoftwareBreadcrumbs items={breadCrumbsData} />
        <PuxContent
          contentModifier='articlePage'
          sideCards={sideCardWidgetData}
        >
          {data.displayText.trim().length && (
            <h1 className='ArticlePage-title'>{data.displayText}</h1>
          )}

          <EasySoftwareEditorContainer
            pageContentItemId={data.contentItemId}
            className='ArticlePage-perex perex'
            content={data.articlePerex}
            editorPath={`articlePerex,html`}
            wysiwygId={data.contentItemId + `-perex`}
          />

          <EasySoftwareEditorContainer
            pageContentItemId={data.contentItemId}
            className='ArticlePage-content'
            content={data.articleContent}
            editorPath={`articleContent,html`}
            wysiwygId={data.contentItemId + `-content`}
          />
          <WidgetBuilder
            pageContentItemId={data.contentItemId}
            widgetBuilderData={widgetsData?.articleWidgets}
            editorPath={editorPath}
          />
        </PuxContent>
      </div>
    </Layout>
  )
}

export default ArticlePage

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareArticlePage>>
) {
  return (
    <PuxMetaTagsGatsby
      {...props.pageContext.metaTags}
      localizedPaths={props.pageContext.pageData.localization?.localizations}
    />
  )
}
