//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import { BasePage, GenericPageProps } from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const PuxHomePage: FunctionComponent<GenericPageProps<BasePage>> = ({
  pageContext,
}) => {
  const structuredData = {
    '@context': `https://schema.org`,
    '@type': `WebApplication`,
    name: `Easy Redmine`,
    offers: {
      '@type': `Offer`,
      price: `78.00`,
      priceCurrency: `USD`,
    },
    aggregateRating: {
      '@type': `AggregateRating`,
      ratingValue: `4.5`,
      bestRating: `5`,
      ratingCount: `57`,
    },
    applicationCategory: `BusinessApplication`,
    sameAs: [
      `https://www.facebook.com/easyredmine/`,
      `https://twitter.com/easyredmine`,
      `https://www.youtube.com/channel/UC8lBlJxEOZ_FYZ-KZYbz5Cw`,
    ],
    image: `redminelogo.png`,
  }

  const pageData = pageContext.pageData
  const headerData = pageContext.headerData
  const footerData = pageContext.footerData

  const editorPath = `widgetZone,widgets`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      pageContentItemId={pageData.contentItemId}
      footerData={footerData}
      headerData={headerData}
      pageProperties={pageContext.pageProperties}
    >
      <PuxStructuredData structuredData={structuredData} />
      <WidgetBuilder
        widgetBuilderData={pageContext.widgets?.widgetZone}
        pageContentItemId={pageData.contentItemId}
        editorPath={editorPath}
      />
    </Layout>
  )
}

export default PuxHomePage

export function Head(props: HeadProps<never, IPageContext<BasePage>>) {
  return (
    <PuxMetaTagsGatsby
      {...props.pageContext.metaTags}
      localizedPaths={props.pageContext.pageData.localization?.localizations}
    />
  )
}
